import axios from 'axios'
import $ from 'jquery'
import { resolve } from 'dns'
import { saveAs } from 'file-saver'

class QrGenerator {
  constructor() {
    this.initSelect()
    this.onChange()
  }

  initSelect() {
    document.querySelectorAll('select.js-select').forEach((selectEl) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('select')
      selectEl.parentNode.insertBefore(wrapper, selectEl);
      wrapper.appendChild(selectEl);

      wrapper.dataset.content = selectEl.options[selectEl.selectedIndex].dataset.content;

      const template = document.createElement('template');
      template.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-rule="evenodd" fill="currentColor" d="M11.66 18.256c.167.17.426.19.613.057l.066-.057L23.86 6.44c.188-.192.188-.504 0-.696-.166-.17-.425-.19-.612-.057l-.066.057L12 17.212.82 5.744c-.167-.17-.426-.19-.613-.057l-.066.057c-.167.171-.186.436-.056.628l.056.068 11.52 11.816z" /></svg>';
      wrapper.appendChild(template.content);

      selectEl.addEventListener('input', (event) => {
        wrapper.dataset.content = selectEl.options[selectEl.selectedIndex].dataset.content;
      });
    })
  }

  onChange() {
    const form = document.forms.data
    for (const element of form.elements) {
      element.addEventListener('input', (event) => {
        this.activateNextSteps()
        this.loadQrCode()
      })
    }
  }

  activateNextSteps() {
    const form = document.forms.data
    const rows = form.querySelectorAll('.form__row[data-step]')
    for (const [index, row] of Object.entries(rows)) {
      let valid = true
      for (const element of form.elements) {
        const elementRow = element.closest('.form__row[data-step]')
        if (row == elementRow && !element.checkValidity()) {
          valid = false
        }
      }
      if (valid) {
        row.dataset.step = '1'
        if (Number(index) + 1 < rows.length) {
          rows[Number(index) + 1].dataset.active = '1'
        }
      } else {
        break
      }
    }
  }

  loadQrCode() {
    const downloadEl = document.getElementById('download')
    const finalStep = document.forms.data.querySelector('.form__row[data-final-step]')
    if (document.forms.data.checkValidity()) {
      finalStep.dataset.active = '1'

      this.setLoadingScreen(1)

      const url = new URL(`${location.origin}/qrcode.php`);
      
      const formData = new FormData(document.forms.data);
      for (const [name, value] of formData) {
        url.searchParams.set(name, value);
      }

      const imageEl = document.getElementById('image')
      imageEl.addEventListener('load', () => {
        this.hidePreviewDiv(false)
        this.setLoadingScreen(0)
      }, {
        once: true
      })
      imageEl.src = url.toString()

      downloadEl.href = url.toString()
    } else {
      finalStep.dataset.active = '0'
      this.hidePreviewDiv(true)
      downloadEl.href = ''
    }
  }

  hidePreviewDiv(hide = false) {
    const $previewInfoDiv = $('.preview__no-preview')
    const $previewDiv = $('.preview__file')
    const $previewDivPDFContainer = $('.preview__container')

    if (hide) {
      $previewDiv.hide()
      $previewInfoDiv.show()
      $previewDivPDFContainer.addClass('with-border')
    } else {
      $previewDiv.show()
      $previewInfoDiv.hide()
      $previewDivPDFContainer.removeClass('with-border')
    }
  }

  setLoadingScreen(status) {
    const $loadingField = $('.preview__loading')
    status == 0 ? $loadingField.removeClass('load') : $loadingField.addClass('load')
  }
}

window.onload = () => {
  new QrGenerator()
}
